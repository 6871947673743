<template>
  <div class="LandingView">
    <HeaderComponent
      v-show="detectCountry && ready"
      class="fade-enter-active"
    />
    <SetCountryComponent
      v-show="!detectCountry && ready"
      class="slide-in-bottom"
      style="animation-duration: 1.4s"
    />
    <router-view class="slide-in-bottom" style="animation-duration: 1.4s" />
    <FooterComponent v-show="detectCountry && ready" />
    <FooterDefaultComponent v-show="!(detectCountry && ready)" />
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent.vue'
import './_LandingView.scss'
import FooterComponent from '@/components/FooterComponent/FooterComponent.vue'
import SetCountryComponent from '@/components/SetCountryComponent/SetCountryComponent.vue'
import FooterDefaultComponent from '@/components/FooterDefaultComponent/FooterDefaultComponent.vue'

export default {
  name: 'LandingView',
  components: { FooterComponent, HeaderComponent, SetCountryComponent, FooterDefaultComponent },
  data () {
    return {
      detectCountry: false,
      ready: false
    }
  },
  mounted () {
    this.$store.commit('loadData')
    setTimeout(() => { this.detect() }, 1500)
  },
  methods: {
    detect () {
      this.ready = true
      if (this.$route.meta.country || this.$store.state.countries[this.$store.state.country]) {
        this.detectCountry = true
        return
      }
      this.detectCountry = false
    }
  }
}
</script>
