<template>
    <div class="FooterComponent">
        <v-footer dark color="#0C131D" class="pa-0">
          <div align="center" class="support d-flex" style="height: 200px; align-items: center" v-if="!$route.meta.auth">
          <v-container class="py-5">
            <v-row>
                <v-col cols="12" sm="4" class="pa-0" align-self="center" align="center">
                  <div class="d-flex pa-2" :style='{ "align-items": "center", "place-content": $vuetify.breakpoint.smAndUp ? "center" : "left" }'>
                    <div class="mx-4">
                      <v-icon color="customColor" flat size="40">mdi-email-outline</v-icon>
                    </div>
                    <div>
                      <div class="support title">Contacto</div>
                      <div class="support">contacto@passpoint.cl</div>
                    </div>
                  </div>
                </v-col>
              <v-col cols="12" sm="4" class="pa-0" align-self="center" align="center">
                <div class="d-flex pa-2" :style='{ "align-items": "center", "place-content": $vuetify.breakpoint.smAndUp ? "center" : "left" }'>
                  <div class="mx-4">
                    <v-icon color="customColor" flat size="40">mdi-checkbox-marked-outline</v-icon>
                  </div>
                  <div>
                    <div class="support title">Pago Seguro</div>
                    <div class="support">Agencia Lider</div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="4" class="pa-0" align-self="center" align="center">
                <div class="d-flex pa-2" :style='{ "align-items": "center", "place-content": $vuetify.breakpoint.smAndUp ? "center" : "left" }'>
                  <div class="mx-4">
                    <v-icon color="customColor" flat size="40">mdi-headset</v-icon>
                  </div>
                  <div>
                    <div class="support title">Soporte Experto</div>
                    <div class="support">soporte@passpoint.cl</div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          </div>
          <v-container fluid class="pa-0">
            <v-row>
              <v-col align-self="center" align="center">
                <div class="my-10 d-inline-flex">
                  <div class="overIcon facebook mx-5">
                    <v-icon>mdi-facebook</v-icon>
                  </div>
                  <div class="overIcon linkedin mx-5">
                    <v-icon>mdi-linkedin</v-icon>
                  </div>
                  <div class="overIcon instagram mx-5">
                    <v-icon>mdi-instagram</v-icon>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="center">
                <div class="my-10">
                <div class="callJoin">Únete escribiendo a</div>
                <div class="callJoin"><a href="mailto:chile@passpoint.cl"><strong>contacto@passpoint.cl</strong></a></div>
                </div>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col align-self="center" align="center">
                <v-img class="my-2" :src="require('@/assets/logos/2024_ProyectoApoyadoPorCORFO_Blanco.png')" max-width="250px" width="auto" max-height="150px" contain/>
                <div class="powered">
                  © 2024 Powered by PassPoint
                </div>
                <hr class="my-10" />
                <div class="mb-5">
                  <v-btn plain class="text-decoration-underline" small to="/chile/legal" @click="scrollToTop">Terminos legales</v-btn>
                  <v-btn plain class="text-decoration-underline" small to="/chile/faq" @click="scrollToTop">Preguntas Frecuentes</v-btn>
                  <v-btn plain class="text-decoration-underline" small to="/chile/usuario" @click="scrollToTop">Beneficios Usuario</v-btn>
                  <v-btn plain class="text-decoration-underline" small to="/chile/comercio" @click="scrollToTop">Beneficios Comercio</v-btn>
                  <v-btn plain class="text-decoration-underline" small to="/chile/empresa" @click="scrollToTop">Beneficios Empresa</v-btn>
                  <v-btn plain class="text-decoration-underline" small to="/chile/soporte" @click="scrollToTop">Soporte</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
    </div>
</template>

<script>
import './_FooterComponent.scss'
export default {
  name: 'FooterComponent',
  methods: {
    scrollToTop () {
      setTimeout(() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }, 1000)
    }
  }
}
</script>
